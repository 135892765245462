<template>
  <div class="deviceHouseForm-container">
    <form-panel
      ref="form"
      :form="form"
      :queryUrl="queryUrl"
      :submitUrl="submitUrl"
      v-bind="submitConfig"
      @update="update"
    >
      <col2-detail>
        <col2-block title="基本信息">
          <el-form-item label="设备房名称" prop="name" :rules="[{ required: true, message: '请填写设备房名称', trigger: 'blur'}]">
            <v-input v-model="form.name" />
          </el-form-item>
          <el-form-item label="分类" prop="categoryId" :rules="[{ required: true, message: '请选择分类', trigger: 'change'}]">
            <v-select2 v-model="form.categoryId" v-bind="categoryParams"></v-select2>
          </el-form-item>
          <el-form-item label="归属项目" prop="communityId" :rules="[{ required: true, message: '请选择归属项目', trigger: 'change'}]">
            <v-select2 v-model="form.communityId" v-bind="communityParams" @onChange="communityChange"></v-select2>
          </el-form-item>
          <el-form-item label="所属组团" prop="area" :rules="[{ required: true, message: '请选择所属组团', trigger: 'change'}]">
            <v-select v-model="form.area" :options="areaOps"></v-select>
          </el-form-item>
          <el-form-item label="安装位置" prop="location" :rules="[{ required: true, message: '请填写安装位置', trigger: 'blur'}]">
            <v-input v-model="form.location"></v-input>
          </el-form-item>
          <el-form-item label="关联蓝牙" prop="bluetoothId" :rules="[{ required: true, message: '请选择关联蓝牙', trigger: 'change'}]">
            <v-select2 ref="bluetoothSelect" v-model="form.bluetoothId" v-bind="bluetoothParams" :subjoin="bluetoothExtraParams"></v-select2>
          </el-form-item>
          <el-form-item label="备注">
            <v-ueditor v-model="form.remarks"></v-ueditor>
          </el-form-item>
        </col2-block>
        <col2-block title="关联设备">
          <el-form-item label="设施设备">
            <chosenListPanel :list.sync="deviceList" @select="toSelectTag"></chosenListPanel>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
    <multi-select
      title="设备列表"
      :isShow.sync="isShow"
      :searchUrl="getDeviceListUrl"
      :extraParams="extraParams"
      :headers="deviceTableHeader"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :backFill.sync="deviceList">
        <template #searchSlot>
          <v-input v-model="searchParams.name" label="设备名称"></v-input>
          <v-input v-model="searchParams.serialNum" label="设备编号"></v-input>
          <v-select v-model="searchParams.deviceStatus" label="状态" :options="deviceStatusOps"></v-select>
          <v-input v-model="searchParams.address" label="安装位置"></v-input>
          <v-input v-model="searchParams.chargerName" label="责任人"></v-input>
          <v-select2 v-model="searchParams.maintCompanyId" v-bind="maintCompanyParams" label="维保单位"></v-select2>
        </template>
    </multi-select>
  </div>
</template>
<script>
import {
  getCateGoryURL,
  saveDeviceRoomURL,
  updateDeviceRoomURL,
  getDeviceRoomURL,
  getBlueteethURL,
  getCategoryCommonURL,
  getDeviceListURL
  // getBlockNameFromCommunityURL
} from './api'
import { Col2Block, Col2Detail, ChosenListPanel, MultiSelect } from '@/components/bussiness'
import { vUeditor } from 'components/control'
import { deviceStatusOps } from './map'
import { normalCommunityParams } from 'common/select2Params'
import { select2BlockURL } from 'common/api'

export default {
  components: {
    Col2Block,
    Col2Detail,
    vUeditor,
    ChosenListPanel,
    MultiSelect
  },
  data () {
    return {
      queryUrl: getDeviceRoomURL,
      submitUrl: this.$route.query.id ? updateDeviceRoomURL : saveDeviceRoomURL,
      getDeviceListUrl: getDeviceListURL,
      submitConfig: {
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      deviceStatusOps,
      areaOps: [
        {
          text: '无',
          value: '无'
        }
      ],
      form: {
        name: '',
        communityId: '',
        area: '无',
        location: '',
        remarks: '',
        deviceIds: '',
        categoryId: '',
        bluetoothId: ''
      },
      categoryParams: {
        searchUrl: getCateGoryURL,
        request: {
          text: 'value',
          value: 'id'
        },
        response: {
          text: 'value',
          value: 'id'
        }
      },
      communityParams: normalCommunityParams,
      // bluetoothExtraParams: {
      //   communityId: ''
      // },
      bluetoothParams: {
        searchUrl: getBlueteethURL,
        request: {
          text: 'serialNum',
          value: 'id'
        },
        response: {
          text: 'serialNum',
          value: 'id'
        }
      },
      deviceList: [],
      isShow: false,
      searchParams: {
        name: '',
        serialNum: '',
        deviceStatus: undefined,
        address: '',
        chargerName: '',
        maintCompanyId: ''
      },
      extraParams: {
        communityId: '',
        fromDeviceRoom: true,
        deviceRoomId2: this.$route.query.id
      },
      deviceTableHeader: [
        {
          prop: 'name',
          label: '设备名称'
        }, {
          prop: 'serialNum',
          label: '设备编号'
        }, {
          prop: 'address',
          label: '安装位置'
        }, {
          prop: 'deviceStatus',
          label: '状态'
        }, {
          prop: 'maintCommpanyName',
          label: '维保单位'
        }, {
          prop: 'chargerUserName',
          label: '责任人'
        }
      ],
      responseParams: {
        id: 'id',
        name: 'name'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      maintCompanyParams: {
        searchUrl: getCategoryCommonURL,
        request: {
          text: 'content',
          value: 'id'
        },
        response: {
          text: 'content',
          value: 'id'
        }
      }
    }
  },
  computed: {
    bluetoothExtraParams () {
      if (this.form.communityId) {
        return {
          communityId: this.form.communityId,
          area: this.form.area
        }
      } else {
        return {}
      }
    }
  },
  mounted () {
    this.$route.query.id && this.$refs.form.getData({ id: this.$route.query.id })
  },
  methods: {
    update (data) {
      this.form.id = data.id
      this.form.name = data.name
      this.form.categoryId = data.categoryId
      this.form.communityId = data.communityId
      this.form.remarks = data.remarks
      this.form.location = data.location
      // this.$set(this.bluetoothExtraParams, 'communityId', data.communityId)
      this.getBlockNameList(data.communityId, data.area)
      this.form.bluetoothId = data.bluetoothId
      this.deviceList = data.devices.map(item => {
        return {
          text: item.name,
          id: item.id
        }
      })
    },
    toSelectTag () {
      this.isShow = true
    },
    communityChange (val, isFirst) {
      if (isFirst) {
        return false
      }
      this.$refs.bluetoothSelect.clearValue()
      this.$set(this.form, 'area', '无')
      this.areaOps = [{ text: '无', value: '无' }]
      if (val) {
        this.$set(this.extraParams, 'communityId', val.id)
        // this.$set(this.bluetoothExtraParams, 'communityId', val.id)
        this.getBlockNameList(val.id)
      }
    },
    getBlockNameList (id, value) {
      let params = { communityId: id }
      this.$axios.get(select2BlockURL, { params }).then(res => {
        if (res.status === 100) {
          let defaultOps = {
            text: '无',
            value: '无'
          }
          let areaList = res.data.map(val => {
            return {
              text: val.name,
              value: val.name
            }
          })
          this.areaOps = [defaultOps, ...areaList]
          value && (this.form.area = value)
          //等area值更新后，再让bluetoothId变化，达到重新搜索
          let bluetoothId = this.form.bluetoothId;
          this.$set(this.form, 'bluetoothId', '')
          this.$nextTick(() => {
            this.$set(this.form, 'bluetoothId', bluetoothId)
          })
        }
      })
    }
  },
  watch: {
    deviceList (arr) {
      let ids = arr.map((value) => value.id)
      this.form.deviceIds = JSON.stringify(ids)
    }
  }
}
</script>
