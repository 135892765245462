var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "deviceConfigList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "",
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          searchParams: _vm.searchParams,
          extraParams: _vm.extraParams,
          headers: _vm.headers,
          isMultiSelect: true,
          "page-size": 200,
          isOld: true,
          multiExport: _vm.multiExport,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
          searchSuccess: _vm.searchSuccess,
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "批量修改", permission: "update" },
                  on: {
                    click: function ($event) {
                      return _vm.goPage("batchEdit")
                    },
                  },
                }),
                _vm.userType === "101"
                  ? _c("v-button", {
                      attrs: { text: "生成物管台账", permission: "add" },
                      on: { click: _vm.createDb },
                    })
                  : _vm._e(),
                _c("v-button", {
                  attrs: { text: "登记设备", permission: "add" },
                  on: {
                    click: function ($event) {
                      return _vm.goPage("deviceConfigForm")
                    },
                  },
                }),
                _c("v-button", {
                  attrs: { text: "导入台账", permission: "importDevice" },
                  on: {
                    click: function ($event) {
                      return _vm.goPage("deviceConfigImport")
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "tableLeftSlot",
            fn: function () {
              return [
                _c("div", { staticClass: "tree-group" }, [
                  _c(
                    "div",
                    { staticClass: "tree-group-header" },
                    [
                      _c("div", [_vm._v("设备分类")]),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.cancleTree },
                        },
                        [_vm._v("取消")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "tree-group-content" },
                    [
                      _c("v-tree", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.treeLoading,
                            expression: "treeLoading",
                          },
                        ],
                        ref: "tree",
                        attrs: {
                          data: _vm.treeData,
                          defaultProps: _vm.defaultProps,
                          "expand-on-click-node": false,
                          highlightCurrent: _vm.highlightCurrent,
                        },
                        on: { nodeClick: _vm.nodeClick },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-datepicker", {
                  attrs: {
                    label: "登记时间段",
                    startTime: _vm.searchParams.startDate,
                    endTime: _vm.searchParams.endDate,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "startDate", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "startDate", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endDate", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endDate", $event)
                    },
                  },
                }),
                _c("v-input", {
                  attrs: { label: "设备名称" },
                  model: {
                    value: _vm.searchParams.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "name", $$v)
                    },
                    expression: "searchParams.name",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "设备编号" },
                  model: {
                    value: _vm.searchParams.serialNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "serialNum", $$v)
                    },
                    expression: "searchParams.serialNum",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属项目" },
                      on: { onChange: _vm.communityChange },
                      model: {
                        value: _vm.searchParams.communityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityId", $$v)
                        },
                        expression: "searchParams.communityId",
                      },
                    },
                    "v-select2",
                    _vm.communityParams,
                    false
                  )
                ),
                _c("v-select", {
                  attrs: { label: "状态", options: _vm.deviceStatusOps },
                  model: {
                    value: _vm.searchParams.deviceStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "deviceStatus", $$v)
                    },
                    expression: "searchParams.deviceStatus",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "安装位置" },
                  model: {
                    value: _vm.searchParams.address,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "address", $$v)
                    },
                    expression: "searchParams.address",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "组团", options: _vm.areaOps },
                  model: {
                    value: _vm.searchParams.area,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "area", $$v)
                    },
                    expression: "searchParams.area",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属公司" },
                      model: {
                        value: _vm.searchParams.regionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "regionId", $$v)
                        },
                        expression: "searchParams.regionId",
                      },
                    },
                    "v-select2",
                    _vm.tenantParams,
                    false
                  )
                ),
                _c("v-select", {
                  attrs: { label: "是否巡检", options: _vm.patrolStatusOps },
                  model: {
                    value: _vm.searchParams.isPatrol,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "isPatrol", $$v)
                    },
                    expression: "searchParams.isPatrol",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: "是否维保",
                    options: _vm.maintenanceStatusOps,
                  },
                  model: {
                    value: _vm.searchParams.isMaint,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "isMaint", $$v)
                    },
                    expression: "searchParams.isMaint",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: "巡检/维保方式",
                    options: _vm.workTypeSearchOps,
                  },
                  model: {
                    value: _vm.searchParams.workType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "workType", $$v)
                    },
                    expression: "searchParams.workType",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "登记人" },
                  model: {
                    value: _vm.searchParams.userName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userName", $$v)
                    },
                    expression: "searchParams.userName",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "设备房" },
                      model: {
                        value: _vm.searchParams.deviceRoomId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "deviceRoomId", $$v)
                        },
                        expression: "searchParams.deviceRoomId",
                      },
                    },
                    "v-select2",
                    _vm.deviceRoomParams,
                    false
                  )
                ),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "维保单位" },
                      model: {
                        value: _vm.searchParams.maintCompanyId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "maintCompanyId", $$v)
                        },
                        expression: "searchParams.maintCompanyId",
                      },
                    },
                    "v-select2",
                    _vm.maintCompanyParams,
                    false
                  )
                ),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "通用内容" },
                      model: {
                        value: _vm.searchParams.commonContent,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "commonContent", $$v)
                        },
                        expression: "searchParams.commonContent",
                      },
                    },
                    "v-select2",
                    _vm.commonContentParams,
                    false
                  )
                ),
                _c("v-input", {
                  attrs: { label: "品牌" },
                  model: {
                    value: _vm.searchParams.brand,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "brand", $$v)
                    },
                    expression: "searchParams.brand",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "编辑", type: "text", permission: "update" },
                  on: {
                    click: function ($event) {
                      return _vm.edit(scope.row)
                    },
                  },
                }),
                _c(
                  "el-dropdown",
                  {
                    attrs: { trigger: "click" },
                    on: { command: _vm.moreHandle },
                  },
                  [
                    _c("span", { staticClass: "el-dropdown-link" }, [
                      _vm._v("\n          更多"),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right",
                      }),
                    ]),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: { row: scope.row, type: 1 } } },
                          [_c("span", [_vm._v("生成二维码")])]
                        ),
                        scope.row.isPatrol !== 0
                          ? _c(
                              "el-dropdown-item",
                              {
                                attrs: { command: { row: scope.row, type: 2 } },
                              },
                              [_c("span", [_vm._v("生成巡检日志")])]
                            )
                          : _vm._e(),
                        scope.row.isMaint !== 0
                          ? _c(
                              "el-dropdown-item",
                              {
                                attrs: { command: { row: scope.row, type: 3 } },
                              },
                              [_c("span", [_vm._v("生成维保计划")])]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "batchSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: {
                    text: "批量生成二维码",
                    disabled: scope.selectedData.ids.length === 0,
                    permission: "generateQrcode",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.batchCreateQrcode(scope.selectedData)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: {
                    text: "批量生成巡检日志",
                    disabled: scope.selectedData.ids.length === 0,
                    permission: "InspectionLog",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.batchCreatePatrolRecord(scope.selectedData)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: {
                    text: "批量生成维保计划",
                    disabled: scope.selectedData.ids.length === 0,
                    permission: "maintenancePlan",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.batchCreateMaintRecord(scope.selectedData)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: {
                    text: "批量删除",
                    disabled: scope.selectedData.ids.length === 0,
                    permission: "BatchDele",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.batchDelete(scope.selectedData)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "下载二维码",
            width: "500px",
            visible: _vm.dialogQrcode,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogQrcode = $event
            },
            closed: _vm.closeDialog,
          },
        },
        [
          _c(
            "div",
            { staticClass: "qrcode-wrapper" },
            [
              _c("div", [
                _c("h3", { staticClass: "title-text" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.qrCodeObj.name) + "\n        "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "qrcode-value" }, [
                _c("span", [
                  _vm._v(" 二维码值：" + _vm._s(_vm.qrCodeObj.code) + " "),
                ]),
              ]),
              _c("div", { staticClass: "qrcode-img-wrapper" }, [
                _vm.qrCodeId
                  ? _c("div", {
                      staticClass: "img",
                      attrs: { id: _vm.qrCodeId },
                    })
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "qrcode-value" }, [
                _c("span", [
                  _vm._v(" 位置：" + _vm._s(_vm.qrCodeObj.address) + " "),
                ]),
              ]),
              _c("v-button", {
                attrs: { text: "保存到本地", type: "success" },
                on: { click: _vm.downloadQRCode },
              }),
            ],
            1
          ),
        ]
      ),
      _c("qrcode-collection", {
        attrs: { visible: _vm.dialogQrcodeList, "qrcode-list": _vm.qrcodeList },
        on: {
          "update:visible": function ($event) {
            _vm.dialogQrcodeList = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "qrcodeSlot",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "column",
                    },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(scope.row.serialNum))]),
                    _c("span", [_vm._v(_vm._s(scope.row.name))]),
                    _c("span", [_vm._v(_vm._s(scope.row.address))]),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }